#app {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}
.testimonialSwiper {
    padding-inline: 10px;
}
.testimonialSwiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.insuranceSwiper .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonialSwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonialSwiper .swiper-pagination-bullet-active {
    background: #47BEB9 !important;
}


.testimonialSwiper .swiper-pagination {
   text-align: center;
}
.testimonialSwiper .swiper-pagination-bullet-active {
    background: #49C0B3 !important;
}
.testimonialSwiper .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
    display: inline-block;
    background-color: #E5E5E5;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.chatSwiper {
    overflow: hidden;
    height: 750px;
    position: relative;
}

.chatSwiper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatSwiper .swiper-slide-shadow {
    display: none;
}

.chatSwiper .swiper-wrapper img {
    max-height: none;
}

.chatSwiper .swiper-pagination {
    position: absolute;
    top: 720px;
    left: 0px;
    width: 100%;
    text-align: center;
}

.chatSwiper .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
    display: inline-block;
    background-color: #E5E5E5;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.chatSwiper .swiper-pagination-bullet-active {
    background: #49C0B3 !important;
}

.chatSwiper .swiper-button-next::after {
    color: #000;
    font-weight: bold;
    font-size: 34px;
}

.chatSwiper .swiper-button-prev::after {
    color: #000;
    font-weight: bold;
    font-size: 34px;
}

@media (max-width: 1200px) {
    .chatSwiper {
   
        height: auto;
    }

    .chatSwiper .swiper-slide {
        align-items: start;
    }

    .chatSwiper .swiper-wrapper img {
        width: 100%;
        height: auto;
        max-width: 400px;
    }
}
@media (max-width: 599.98px) {
    
}